import React, { Component, useState } from "react";
import { Link, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import GithubLogo from "./GithubLogo";
import heart from "../images/donate-heart.svg";
import heartFull from "../images/donate-heart-full.svg";
import logo from "./../images/OHIF-logo-195x40-2.svg";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import chevronDown from "../images/chevron-down.png";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSmallerMenu: false,
      donateIsHovered: false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  // function to handle the click
  handleClick() {
    this.setState((prevState) => ({
      showSmallerMenu: !prevState.showSmallerMenu
    }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.clicked !== this.props.clicked) {
      this.setState({ showSmallerMenu: false });
    }
  }

  render() {
    const smallerMenu = (
      <div className="ml-4 mt-8">
        <ul className="lg:hidden space-y-2">
          <PrimaryNavbarLink href="/features" classDec="menuHeight">
            Features
          </PrimaryNavbarLink>
          <DropdownMenu
            title="Showcase"
            isMobile={true}
            options={[
              { label: "Product showcase", href: "/showcase" },
              { label: "Extensible mode gallery", href: "/modes" }
            ]}
          />
          <DropdownMenu
            title="Learn"
            isMobile={true}
            options={[
              {
                label: "Documentation",
                link: "https://docs.ohif.org"
              },
              {
                label: "OHIF User Guide",
                link: "https://docs.ohif.org/user-guide/"
              },
              {
                label: "Developer Quickstart",
                link: "https://docs.ohif.org/development/getting-started/"
              },
              {
                label: "Contributing Guide",
                link: "https://docs.ohif.org/development/our-process/#contributions-pull-requests"
              }
            ]}
          />
          <DropdownMenu
            title="About"
            isMobile={true}
            options={[
              { label: "About the Project", href: "/about" },
              { label: "Team & Collaborators", href: "/team" },
              { label: "Get Support", href: "/get-support" },
              { label: "Funding History", href: "/history" }
            ]}
          />
          <DropdownMenu
            title="Community"
            isMobile={true}
            options={[
              { label: "Release Notes", href: "/release-notes" },
              { label: "Collaborate", href: "/collaborate" },
              { label: "Newsletters", href: "/news" },
              { label: "Roadmap", href: "/roadmap" }
            ]}
          />
        </ul>

        <ul className="flex flex-row items-center mt-2 lg:hidden">
          <SecondaryNavbarLink
            href="https://github.com/OHIF/Viewers"
            isInternal={false}
            classDec="menuHeight"
          >
            <div className="flex items-center">
              <GithubLogo />
              <span className="pl-1">GitHub</span>
            </div>
          </SecondaryNavbarLink>
          <SecondaryNavbarLink
            href="/donate"
            isInternal={true}
            classDec="menuHeight"
          >
            Donate
          </SecondaryNavbarLink>
        </ul>
      </div>
    );

    return (
      <div className="z-50 p-3 text-white bg-blue-800 w-full header-mobile">
        <div>
          <nav className="flex flex-row justify-between mx-4 lg:mx-0 text-[18px]">
            <div className="flex flex-row mt-2">
              {/* LOGO */}
              <Link to="/" className="mr-12 flex-shrink-0">
                <img
                  src={logo}
                  alt="Open Health Imaging Foundation"
                  height="40"
                  width="195"
                  onContextMenu={(e) => {
                    e.preventDefault();
                    navigate("/assets");
                  }}
                />
              </Link>
              {/* LEFT */}
              <ul className="hidden mt-2 lg:flex lg:flex-row space-x-3 ">
                <div className="mx-3">
                  <PrimaryNavbarLink href="/features">
                    Features
                  </PrimaryNavbarLink>
                </div>
                <div className="mx-3">
                  <DropdownMenu
                    title="Showcase"
                    options={[
                      { label: "Product showcase", href: "/showcase" },
                      { label: "Extensible mode gallery", href: "/modes" }
                    ]}
                  />
                </div>
                <div className="mx-3">
                  <DropdownMenu
                    title="Learn"
                    options={[
                      {
                        label: "Documentation",
                        link: "https://docs.ohif.org"
                      },
                      {
                        label: "OHIF User Guide",
                        link: "https://docs.ohif.org/user-guide/"
                      },
                      {
                        label: "Developer Quickstart",
                        link: "https://docs.ohif.org/development/getting-started/"
                      },
                      {
                        label: "Contributing Guide",
                        link: "https://docs.ohif.org/development/our-process/#contributions-pull-requests"
                      }
                    ]}
                  />
                </div>
                <div className="mx-3">
                  <DropdownMenu
                    title="About"
                    options={[
                      { label: "About the Project", href: "/about" },
                      { label: "Team & Collaborators", href: "/team" },
                      { label: "Get Support", href: "/get-support" },
                      { label: "Funding History", href: "/history" }
                    ]}
                  />
                </div>
                <div className="mx-3">
                  <DropdownMenu
                    title="Community"
                    options={[
                      { label: "Release Notes", href: "/release-notes" },
                      { label: "Collaborate", href: "/collaborate" },
                      { label: "Newsletters", href: "/news" },
                      { label: "Roadmap", href: "/roadmap" }
                    ]}
                  />
                </div>
              </ul>
            </div>
            {/* RIGHT */}
            <ul className="items-center hidden mt-2 lg:flex lg:flex-row">
              <SecondaryNavbarLink
                href="https://github.com/OHIF/Viewers"
                isInternal={false}
              >
                <div className="flex space-x-2 items-center hover:opacity-75">
                  <GithubLogo className="github-icon" />
                </div>
              </SecondaryNavbarLink>
              <SecondaryNavbarLink
                href="/donate"
                isInternal={true}
                isLast={true}
              >
                <button
                  className="flex items-center pl-2 pr-3 py-1 rounded-md bg-border-color hover:bg-hover-blue"
                  onMouseEnter={() => this.setState({ donateIsHovered: true })}
                  onMouseLeave={() => this.setState({ donateIsHovered: false })}
                >
                  <img
                    src={this.state.donateIsHovered ? heartFull : heart}
                    className="donate-heart w-4 h-4 mr-2"
                  />
                  <span className="donate-text text-white">Donate</span>
                </button>
              </SecondaryNavbarLink>
            </ul>
            {/* HAMBURGER */}
            <div
              className="mt-4 mr-4 lg:hidden cursor-pointer hover:text-blue-100"
              onClick={this.handleClick}
            >
              {this.state.showSmallerMenu ? "X" : "Menu"}
            </div>
          </nav>
          <div className="lg:hidden">
            {this.state.showSmallerMenu ? smallerMenu : ""}
          </div>
        </div>
      </div>
    );
  }
}

function DropdownMenu({ title, options, isMobile }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const getOptions = () => {
    const color = isMobile ? "text-white" : "";

    return options.map((option) => {
      if (option.link) {
        return (
          <a
            key={option.link}
            href={option.link}
            className={`block px-2 py-1 text-sm ${color} flex items-center space-x-1 hover:text-white group`}
            role="menuitem"
            target="_blank"
          >
            <span>{option.label}</span>
            <div className="">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                className={`w-3 h-3`}
              >
                <path
                  fillRule="evenodd"
                  d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z"
                  clipRule="evenodd"
                  fill={isMobile ? "#ffffff" : "#5ACCE6"}
                />
                <path
                  fillRule="evenodd"
                  d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z"
                  clipRule="evenodd"
                  fill={isMobile ? "#ffffff" : "#5ACCE6"}
                />
              </svg>
            </div>
          </a>
        );
      } else {
        return (
          <Link
            key={option.href}
            to={option.href}
            className={`block px-2 py-1 text-sm ${color} hover:text-white`}
            role="menuitem"
          >
            {option.label}
          </Link>
        );
      }
    });
  };

  if (isMobile) {
    return (
      <li onClick={handleToggle} className="cursor-pointer">
        <div className="flex items-center">
          <li className="">{title}</li>
          <div className="w-5 h-5">
            <img src={chevronDown} />
          </div>
        </div>
        {isOpen && <div className="pt-2">{getOptions()}</div>}
      </li>
    );
  } else {
    return (
      <div
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        className="relative mr-3 "
        onClick={handleToggle}
      >
        <div className="flex flex-row items-center">
          <li className="">{title}</li>
          <div className="w-5 h-5">
            <img src={chevronDown} />
          </div>
        </div>
        {isOpen && (
          <div className="text-[#5ACCE6] text-[16px] absolute z-50 left-0 mt-2 rounded-md shadow-lg bg-[#0e133f] cursor-pointer border border-[#4042af] whitespace-nowrap">
            {/* handle mouse hover trick */}
            <div className="w-32 h-8 bg-transparent absolute -top-5" />
            <div className="p-2">
              <div className="absolute left-6 -top-[1px] w-0 h-0 rotate-45">
                <div
                  className="absolute w-3 h-3"
                  style={{
                    transform: "translate(-50%, -50%)",
                    borderTop: "1px solid #4042af",
                    borderLeft: "1px solid #4042af",
                    backgroundColor: "#0e133f",
                    content: ""
                  }}
                ></div>
              </div>

              {getOptions()}
            </div>
          </div>
        )}
      </div>
    );
  }
}

function PrimaryNavbarLink({
  children,
  href,
  isInternal = true,
  classDec,
  blank = true
}) {
  let Dec = classDec === "menuHeight" ? "mr-5 caret" + classDec : "mr-5";

  if (isInternal) {
    return (
      <li className={Dec}>
        <Link
          activeClassName="border-indigo-300"
          className="no-underline border-b-2 border-transparent cursor-pointer hover:border-indigo-500"
          to={href}
        >
          {children}
        </Link>
      </li>
    );
  } else {
    return (
      <li className={Dec}>
        <span className="no-underline border-b-2 border-transparent cursor-pointer hover:border-indigo-500 active:border-indigo-300">
          <OutboundLink
            href={href}
            target={blank ? "_blank" : "_self"}
            rel="noreferrer"
          >
            {children}
          </OutboundLink>
        </span>
      </li>
    );
  }
}

function SecondaryNavbarLink({
  children,
  href,
  isInternal = true,
  classDec,
  isLast
}) {
  let Dec = classDec === "menuHeight" ? "mr-5 " + classDec : "mr-5";

  // remove mr-5 if it's the last element
  if (isLast) {
    Dec = classDec === "menuHeight" ? " " + classDec : "";
  }

  if (isInternal) {
    return (
      <li className={Dec}>
        <Link
          activeClassName="border-indigo-300 nav-secondary-button-active"
          className="text-indigo-500 no-underline border-b-2 border-transparent cursor-pointer hover:border-indigo-500"
          to={href}
        >
          {children}
        </Link>
      </li>
    );
  } else {
    return (
      <li className={Dec}>
        <span className="text-indigo-500 no-underline border-b-2 border-transparent cursor-pointer hover:border-indigo-500 active:border-indigo-300 active:text-blue-100">
          <OutboundLink href={href} target="_blank" rel="noreferrer">
            {children}
          </OutboundLink>
        </span>
      </li>
    );
  }
}

export default Navbar;
