import React, { Component } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      close: false
    };

    this.toggleChildMenu = this.toggleChildMenu.bind(this);
  }

  toggleChildMenu() {
    this.setState((prevState) => ({
      close: !prevState.close
    }));
  }

  render() {
    return (
      <div className="flex flex-col h-screen justify-between bg-blue-800  text-[16px] lg:text-[18px]">
        <div className="page-max-width mx-auto">
          <div className="">
            <Navbar clicked={this.state.close} />
          </div>
          <div className="mb-20 px-8 page-mobile">
            <div id="main-body" onClick={this.toggleChildMenu}>
              {this.props.children}
            </div>
          </div>
        </div>
        <div className="bg-black">
          <Footer />
        </div>
      </div>
    );
  }
}

export default Page;
